import {ComponentMetaData} from './constants';

export default {
  [ComponentMetaData.skinName]: {
    params: {
      desktopVerticalPadding: {
        hide: true,
      },
      desktopHorizontalPadding: {
        hide: true,
      },
      desktopVerticalMargin: {
        hide: true,
      },
      desktopHorizontalMargin: {
        hide: true,
      },
      mobileVerticalPadding: {
        hide: true,
      },
      mobileHorizontalPadding: {
        hide: true,
      },
      mobileVerticalMargin: {
        hide: true,
      },
      mobileHorizontalMargin: {
        hide: true,
      },
      brw: {
        state: 'regular',
        category: 'border',
        label: 'CustomDesign_Borders_Width',
        max: 15,
      },
      brd_s: {
        state: 'selected',
        category: 'border',
        label: 'CustomDesign_Borders_Color&Opacity',
      },
      txt_s: {
        state: 'selected',
        category: 'text',
        label: 'CustomDesign_Text_TextColor',
      },
      bg_s: {
        state: 'selected',
        category: 'fill',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      brd_d: {
        state: 'disabled',
        category: 'border',
        label: 'CustomDesign_Borders_Color&Opacity',
      },
      txt_d: {
        state: 'disabled',
        category: 'text',
        label: 'CustomDesign_Text_TextColor',
      },
      bg_d: {
        state: 'disabled',
        category: 'fill',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      brd_e: {
        state: 'error',
        category: 'border',
        label: 'CustomDesign_Borders_Color&Opacity',
      },
      txt_e: {
        state: 'error',
        category: 'text',
        label: 'CustomDesign_Text_TextColor',
      },
      bg_e: {
        state: 'error',
        category: 'fill',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      brd_h: {
        state: 'hover',
        category: 'border',
        label: 'CustomDesign_Borders_Color&Opacity',
      },
      txt_h: {
        state: 'hover',
        category: 'text',
        label: 'CustomDesign_Text_TextColor',
      },
      bg_h: {
        state: 'hover',
        category: 'fill',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
    },
  },
};
