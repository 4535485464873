export const TranslationKeys = {
  layoutPanel: {
    header: 'SignatureInput-layout-panel-header',
    textAlignment: 'SignatureInput-layout-panel-text-alignment',
    titlePaddingStart: 'SignatureInput-layout-panel-title-padding-start',
    titleMarginBottom: 'SignatureInput-layout-panel-title-input-space',
  },
  settingsPanel: {
    header: 'SignatureInput-settings-panel-header',
    titleText: 'SignatureInput-settings-panel-title',
    titleTextTooltip: 'SignatureInput-settings-panel-title-tooltip',
    titleTextPlaceholder: 'SignatureInput-settings-panel-title-placeholder',
    titleTextTooLong: 'SignatureInput-settings-panel-title-invalid-max-length',
    clearButtonText: 'SignatureInput-settings-panel-clear-button',
    clearButtonTextTooltip:
      'SignatureInput-settings-panel-clear-button-tooltip',
    clearButtonTextPlaceholder:
      'SignatureInput-settings-panel-clear-button-placeholder',
    clearButtonEmpty:
      'SignatureInput-settings-panel-clear-button-invalid-empty',
    clearButtonTooLong:
      'SignatureInput-settings-panel-clear-button-invalid-max-length',
    generalSettingsTitle:
      'SignatureInput-settings-panel-general-settings-title',
    required: 'SignatureInput-settings-panel-required-label',
    requiredTooltip: 'SignatureInput-settings-panel-required-label-tooltip',
    emptyLabelTooltip: 'SignatureInput-settings-label_empty_warning',
    emptyLabelTooltipLearnMore: 'SignatureInput-settings-label_empty_warning_link'
  },
  gfpp: {
    mainAction: 'SignatureInput-settings-panel-main-action',
  },
};

export const DataHooks = {
  coreComponent: 'SignatureInput-core-component',
  title: 'SignatureInput--label',
  clear: 'SignatureInput--clear-button',
  settingsPanel: {
    titleInput: 'SignatureInput-layout-panel--title',
    clearInput: 'SignatureInput-layout-panel--clear',
    requiredCheckbox: 'SignatureInput-layout-panel--required',
  },
  layoutPanel: {
    directionThumbnails: 'SignatureInput-settings-panel--direction',
    titlePaddingStart: 'SignatureInput-settings-panel--title-padding-start',
    titleMarginBottom: 'SignatureInput-settings-panel--title-margin-bottom',
  },
};

export const HelpIds = {
  settingsPanel: '0204d706-a79e-48da-84b3-fbbddc22d5e1',
  layoutPanel: 'ab308a40-ed8e-463d-af49-efe868ae219d',
  gfpp: {
    desktop: 'cefdfa29-6792-48fe-a077-ca7bd230d769',
    mobile: '917142f7-c8e6-4616-839a-3868a27b336c',
  },
};

export const ComponentMetaData = {
  displayName: 'SignatureInput',
  componentType: 'wixui.SignatureInput',
  nickName: 'signatureInput',
  skinName: 'wixui.skins.SignatureInput',
};
