// After all translation keys exist in the component, they should be added to a new file in santa-langs repo
// This is the path where the component translations should be created:
// https://github.com/wix-private/santa-langs/blob/master/src/main/resources/santa-editor/bundles/ImageX/ImageX_en.json
export const TranslationKeys = {
  settingsPanel: {
    header: 'ImageX-settings-panel-header',
  },
  gfpp: {
    mainAction: 'ImageX-settings-panel-main-action',
  },
};

export const DataHooks = {
  coreComponent: 'ImageX-core-component',
  settingsPanel: {
    container: 'ImageX-settings-panel-container',
    text: 'ImageX-settings-panel-text',
  },
};

export const ComponentMetaData = {
  displayName: 'ImageX',
  componentType: 'wixui.ImageX',
  nickName: 'imageX',
  skinName: 'wixui.skins.ImageX',
};
