export default {
  'wixui.skins.MusicPlayer': {
    params: {
      backgroundColorIcon: {
        hide: true,
      },
      regularColorIcon: {
        hide: true,
      },
    },
  }
};
