export const TranslationKeys = {
  label: 'component_label_selection_tags',
  settingsPanel: {
    header: 'selection_tags_settings_header_label',
    submittingLabel: 'selection_tags_settings_submitting_requirements_label',
    requireLabel: 'selection_tags_settings_require_upload_label',
  },
  managePanel: {
    header: 'selection_tags_manage_choices_header_label',
    addItem: 'selection_tags_manage_choices_button_primary_text',
    connectData: 'selection_tags_manage_choices_button_secondary_text',
    defaultOptionLabel: 'selection_tags_manage_choices_default_label',
    clonedOptionLabel: 'selection_tags_manage_choices_copied_tag_name',
    valuePlaceholder: 'selection_tags_manage_choices_edit_value_placeholder',
    labelPlaceholder: 'selection_tags_manage_choices_edit_label_placeholder',
    itemValuePrefix: 'Checkbox_Group_Options_Manager_Value',
    emptyValueError: 'selection_tags_manage_choices_edit_value_empty_error',
    notUniqueValueError:
      'selection_tags_manage_choices_edit_value_unique_error',
    selectAsDefault:
      'selection_tags_manage_choices_tag_options_unselected_default',
    unselectAsDefault:
      'selection_tags_manage_choices_tag_options_selected_default',
    duplicate: 'selection_tags_manage_choices_tag_options_duplicate',
    editValue: 'selection_tags_manage_choices_tag_options_edit_value',
    editLabel: 'selection_tags_manage_choices_tag_options_edit_label',
    delete: 'selection_tags_manage_choices_tag_options_delete',
    doneLabel: 'selection_tags_manage_choices_edit_done',
  },
  layoutPanel: {
    header: 'selection_tags_layout_header_label',
    paddingSectionTitle: 'selection_tags_layout_text_padding_section_title',
    verticalPaddingLabel: 'selection_tags_layout_text_padding_vertical_label',
    horizontalPaddingLabel:
      'selection_tags_layout_text_padding_horizontal_label',
    spacingSectionTitle: 'selection_tags_layout_spacing_section_title',
    alignmentSectionTitle: 'selection_tags_layout_alignment_section_title',
    alignmentSectionLabel: 'selection_tags_layout_tag_alignment_label',
    displayFromTooltip: 'selection_tags_layout_display_from_tooltip',
    displayFromLabel: 'selection_tags_layout_display_from_label',
    displayLtr: 'selection_tags_layout_LtoR',
    displayRtl: 'selection_tags_layout_RtoL',
    verticalSpacingLabel: 'selection_tags_layout_spacing_vertical_label',
    horizontalSpacingLabel: 'selection_tags_layout_spacing_horizontal_label',
  },
  gfpp: {
    mainAction: 'gfpp_mainaction_selection_tags',
  },
};

export const DataHooks = {
  coreComponent: 'SelectionTagsList-core-component',
  settingsPanel: {
    container: 'SelectionTagsList-settings-panel-container',
    checkbox: 'SelectionTagsList-settings-panel-checkbox',
  },
  managePanel: {
    container: 'SelectionTagsList-manage-panel-container',
    dragList: 'SelectionTagsList-manage-panel-drag-list',
    addItemButton: 'SelectionTagsList-manage-panel-add-item-button',
    connectButton: 'SelectionTagsList-manage-panel-connect-button',
  },
  layoutPanel: {
    container: 'SelectionTagsList-layout-panel-container',
    slider: 'SelectionTagsList-layout-panel-slider',
  },
};

export const ComponentMetaData = {
  displayName: 'SelectionTagsList',
  componentType: 'wixui.SelectionTagsList',
  nickName: 'selectionTags',
  skinName: 'wixui.skins.SelectionTagsList',
  corvidName: 'SelectionTags',
};

export const ComponentDataTypes = {
  tagsList: 'SelectionTagsList',
  tag: 'SelectionTag',
};
