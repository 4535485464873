import {ComponentMetaData} from './constants';

export default {
  [ComponentMetaData.skinName]: {
    params: {
      padBackground: {
        state: 'regular',
        category: 'fill',
        priority: '1',
        section: 'background',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      padBackgroundHover: {
        state: 'hover',
        category: 'fill',
        priority: '1',
        section: 'background',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      padBackgroundFocus: {
        state: 'focus',
        category: 'fill',
        priority: '1',
        section: 'background',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      padBackgroundError: {
        state: 'error',
        category: 'fill',
        priority: '1',
        section: 'background',
        label: 'CustomDesign_FillColor_BGColor&Opacity',
      },
      padBackgroundDisabled: {
        state: 'disabled',
        category: 'fill',
        priority: '1',
        section: 'background',
        label: 'CustomDesign_SignatureInput_Background_BGColor',
      },
      padLineColor: {
        state: 'regular',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_Borders_Color&opacity',
        section: 'signatureLine',
      },
      padLineColorHover: {
        state: 'hover',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_Borders_Color&opacity',
        section: 'signatureLine',
      },
      padLineColorFocus: {
        state: 'focus',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_Borders_Color&opacity',
        section: 'signatureLine',
      },
      padLineColorError: {
        state: 'error',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_Borders_Color&opacity',
        section: 'signatureLine',
      },
      padLineColorDisabled: {
        state: 'disabled',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_Borders_Color&opacity',
        section: 'signatureLine',
      },
      padLineWidth: {
        state: 'regular',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureLine',
      },
      padLineWidthHover: {
        state: 'hover',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureLine',
      },
      padLineWidthFocus: {
        state: 'focus',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureLine',
      },
      padLineWidthError: {
        state: 'error',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureLine',
      },
      padLineWidthDisabled: {
        state: 'disabled',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureLine',
      },
      signatureColor: {
        state: 'regular',
        category: 'fill',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Signature_Color',
        section: 'signatureInput',
      },
      signatureSize: {
        state: 'regular',
        category: 'fill',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
        section: 'signatureInput',
        min: 1,
        max: 4,
      },
      padBorderColor: {
        state: 'regular',
        category: 'border',
        priority: '1',
        label: 'CustomDesign_Border_Opacity&Color',
      },
      padBorderColorHover: {
        state: 'hover',
        category: 'border',
        priority: '1',
        label: 'CustomDesign_Border_Opacity&Color',
      },
      padBorderColorFocus: {
        state: 'focus',
        category: 'border',
        priority: '1',
        label: 'CustomDesign_Border_Opacity&Color',
      },
      padBorderColorError: {
        state: 'error',
        category: 'border',
        priority: '1',
        label: 'CustomDesign_Border_Opacity&Color',
      },
      padBorderColorDisabled: {
        state: 'disabled',
        category: 'border',
        priority: '1',
        label: 'CustomDesign_Border_Opacity&Color',
      },
      padBorderWidth: {
        state: 'regular',
        category: 'border',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
      },
      padBorderWidthHover: {
        state: 'hover',
        category: 'border',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
      },
      padBorderWidthFocus: {
        state: 'focus',
        category: 'border',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
      },
      padBorderWidthError: {
        state: 'error',
        category: 'border',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
      },
      padBorderWidthDisabled: {
        state: 'disabled',
        category: 'border',
        priority: '2',
        label: 'CustomDesign_Borders_Width',
      },
      padBorderRadius: {
        state: 'regular',
        category: 'corners',
        priority: '1',
      },
      padBoxShadow: {
        state: 'regular',
        category: 'shadow',
        priority: '1',
      },
      titleColor: {
        state: 'regular',
        category: 'text',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Title_Color',
        section: 'fieldTitle',
      },
      titleColorDisabled: {
        state: 'disabled',
        category: 'text',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Title_Color',
        section: 'fieldTitle',
      },
      titleAsteriskColor: {
        state: 'regular',
        category: 'text',
        priority: '2',
        label: 'CustomDesign_SignatureInput_Required_Asterisk_Color',
        section: 'fieldTitle',
      },
      titleAsteriskColorDisabled: {
        state: 'disabled',
        category: 'text',
        priority: '2',
        label: 'CustomDesign_SignatureInput_Required_Asterisk_Color',
        section: 'fieldTitle',
      },
      titleFont: {
        state: 'regular',
        category: 'text',
        priority: '3',
        section: 'fieldTitle',
      },
      titleFontDisabled: {
        state: 'disabled',
        category: 'text',
        priority: '3',
        section: 'fieldTitle',
      },
      clearButtonColor: {
        state: 'regular',
        category: 'text',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Clear_Button_Color',
        section: 'clearButton',
      },
      clearButtonColorFocus: {
        state: 'focus',
        category: 'text',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Clear_Button_Color',
        section: 'clearButton',
      },
      clearButtonColorDisabled: {
        state: 'disabled',
        category: 'text',
        priority: '1',
        label: 'CustomDesign_SignatureInput_Clear_Button_Color',
        section: 'clearButton',
      },
      clearButtonFont: {
        state: 'regular',
        category: 'text',
        priority: '2',
        section: 'clearButton',
      },
      clearButtonFontDisabled: {
        state: 'disabled',
        category: 'text',
        priority: '2',
        section: 'clearButton',
      },
    },
    sections: {
      background: {
        label: 'CustomDesign_Fill_SignatureInput_Background_Section_Title',
        priority: 1,
      },
      signatureLine: {
        label: 'CustomDesign_Fill_SignatureInput_Signature_Line_Section_Title',
        priority: 2,
      },
      signatureInput: {
        label: 'CustomDesign_Fill_SignatureInput_Signature_Input_Section_Title',
        priority: 3,
      },
      fieldTitle: {
        label: 'CustomDesign_Text_SignatureInput_Field_Title_Section_Title',
        priority: 1,
      },
      clearButton: {
        label: 'CustomDesign_Text_SignatureInput_Clear_Button_Section_Title',
        priority: 2,
      },
    },
  },
};
